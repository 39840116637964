<div class="header-container">
  <div class="logo-container">
    <a (click)="toggleSidebar()" href="#" class="sidebar-toggle" [class.invisible]="isInlineAdmin()">
      <nb-icon icon="menu-2-outline"></nb-icon>
    </a>
    <a class="navbar-brand" style="" [href]="userService.uriFor()">
      <img ngSrc="/assets/images/logo.png" height="43" width="250" alt="Buswhere logo" priority>
      BE ON TIME, EVERY TIME.
    </a>
    <span class="alert alert-warning m-0 p-1 pr-2 pl-2" *ngIf="environmentLabel">{{environmentLabel}}</span>
  </div>
</div>

<div class="header-container" *ngIf="navLinks">
  <div class="navbar-container">
    <a [href]="navLink.urlWithSchool || navLink.url" [class.active]="navLink.active" *ngFor="let navLink of navLinks">
      <div>{{ navLink.label }}</div>
    </a>

    <span class="dropdown" dropdown>
      <a id="button-basic" class="dropdown-toggle" dropdownToggle aria-controls="dropdown-basic" aria-haspopup="true" aria-expanded="false">
        <div>
          <img ngSrc="/assets/images/account-circle.png" height="18" width="18">
          <i class="fa fa-caret-down" aria-hidden="true"></i>
        </div>
      </a>
      <ul id="dropdown-basic"  *dropdownMenu class="dropdown-menu dropdown-menu-left" role="menu" aria-labelledby="button-basic">
        <li role="menuitem">
          <a class="dropdown-item" [href]="userService.uriFor('/users/' + userInfo.id + '/edit')">Account</a>
        </li>
        <li role="menuitem">
          <a class="dropdown-item" (click)="logout()">
            Logout
            <span *ngIf="!!userInfo">{{userInfo.first_name}}</span>
          </a>
        </li>
      </ul>
    </span>

  </div>
</div>
