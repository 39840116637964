import { HttpResponse } from '@angular/common/http';
import {Component, HostListener, OnInit} from '@angular/core';

import { NbSidebarService, NbThemeService } from '@nebular/theme';

import { NavLink, UserInfo } from '@app/interfaces/api.objects';
import { ApiService } from '@app/services/api.service';
import { UserService } from '@app/services/user.service';

import { environment } from '@env';

@Component({
  selector: 'bw-header',
  styleUrls: [ './header.component.scss' ],
  templateUrl: './header.component.html',
})
export class HeaderComponent implements OnInit {

  private readonly SUBSTITUTIONS_PATH = '/schools/admin/substitutions';

  private isSubstitutions: boolean = false;

  environmentLabel: string | null = environment.label;
  navLinks: any[] | null = null;
  userInfo: UserInfo | null = null;

  currentTheme = 'default';

  constructor(
    private apiService: ApiService,
    private sidebarService: NbSidebarService,
    private themeService: NbThemeService,
    protected userService: UserService,
  ) {
  }

  ngOnInit() {
    this.currentTheme = this.themeService.currentTheme;

    this.loadCurrentUser();
    this.loadNavLinks();

    this.isSubstitutions = (window.location.pathname === this.SUBSTITUTIONS_PATH);
  }

  @HostListener('document:schoolsLoaded', ['$event'])
  refreshLinks(ev: any) {
    this.navLinks?.forEach((link:NavLink) => {
      let schoolParam = '';
      if(link.url.includes('?')) {
        schoolParam = `&schoolId=${ev.detail}`;
      } else {
        schoolParam = `?schoolId=${ev.detail}`;
      }

      link.urlWithSchool = link.url + schoolParam;
    });
  };

  isInlineAdmin(): boolean {
    return this.isSubstitutions;
  }

  logout(): void {
    this.userService.logout();
  }

  toggleSidebar(): boolean {
    this.sidebarService.toggle(true, 'menu-sidebar');

    this.sidebarService.getSidebarState('menu-sidebar').subscribe(state => {
      localStorage.setItem('sidebarState', state);
    });

    return false;
  }

  private loadCurrentUser(): void {
    this.userInfo = this.userService.decodedToken();
  }

  private loadNavLinks(): void {
    this.apiService.getLinks((response: HttpResponse<NavLink[]>) => {
      if(response.body != null) {
        this.navLinks = [];
        response.body.forEach((link:NavLink) => {
          if(this.isSubstitutions) {
            link.active = (link.label == 'Admin');
          }
          this.navLinks?.push(link);
        });

      }
    });
  }

}
