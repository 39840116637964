import { DOCUMENT } from '@angular/common';
import { Inject, Injectable } from '@angular/core';

import { JwtHelperService } from '@auth0/angular-jwt';

import { UserInfo } from '@app/interfaces/api.objects';
import { environment } from '@env';

@Injectable({
  providedIn: 'root',
})
export class UserService {
  private readonly BASIC_AUTH_ANSWER = 'mpt';
  private readonly BASIC_AUTH_KEY = 'basicAuth';
  private readonly TOKEN_KEY = 'token';

  constructor(
    private jwtHelper: JwtHelperService,
    @Inject(DOCUMENT) private document: Document,
  ) {
  }

  // true if answer matches expected value
  public basicAuthChallenge(answer: string | null): boolean {
    if (!!answer && (answer === this.BASIC_AUTH_ANSWER)) {
      localStorage.setItem(this.BASIC_AUTH_KEY, '1');
      return true;
    }

    return false;
  }

  // true if we need to perform a bot check
  public basicAuthNeeded(): boolean {
    return !localStorage.getItem(this.BASIC_AUTH_KEY);
  }

  public clearToken(): void {
    localStorage.removeItem(this.TOKEN_KEY);
  }

  public decodedToken(token: string | null = this.getToken()): UserInfo | null {
    if (!token) {
      return null;
    }
    return this.jwtHelper.decodeToken(token);
  }

  public getDecodedToken(): UserInfo | null {
    return this.decodedToken(this.getToken());
  }

  public getId(): number | null {
    return this.getDecodedToken()?.id || null;
  }

  public getUUID(): string | null {
    return this.getDecodedToken()?.uuid || null;
  }

  public getToken(): string | null {
    return localStorage.getItem(this.TOKEN_KEY);
  }

  public hasToken(): boolean {
    return !!this.getToken();
  }

  public isSuperUser(): boolean {
    return this.getDecodedToken()?.super_user || false;
  }

  public logout(): void {
    localStorage.clear();
    this.document.location.href = this.uriFor('/sessions/logout');
  }

  public reload(): void {
    window.stop();
    this.document.location.reload();
  }

  // stores a JWT locally, if it has a more distant expiration
  public storeToken(token: string | undefined): void {
    if (!token) {
      return;
    }

    const newToken = this.decodedToken(token);
    if (!newToken) {
      // should never happen, but skip trying to add invalid tokens
      return;
    }

    const existingToken = this.decodedToken();

    const existingExpiration = existingToken && existingToken.exp;
    const newExpiration = newToken.exp;

    // only replace an existing token if the existing expiration is more recent
    if (!existingExpiration || newExpiration > existingExpiration) {
      localStorage.setItem(this.TOKEN_KEY, token);
    }
  }

  public uriFor(endpoint: string = ''): string {
    return environment.backendUri + endpoint;
  }

}
