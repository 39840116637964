import { Injectable } from '@angular/core';
import {CookieService} from "ngx-cookie-service";
import { environment } from '@env';
import {SchoolEntry} from "@app/interfaces/api.objects";
import {Route} from "@app/interfaces/api.objects";
import { DateTime } from 'luxon';

@Injectable({
  providedIn: 'root',
})
export class SchoolsService {
  _currentSchoolId: number | null = null;
  _isLoading: boolean = true;
  schoolMap: Map<number, SchoolEntry> = new Map();

  constructor(
    protected cookieService: CookieService,
  ) {
  }

  public setMap(map: Map<number, SchoolEntry>) {
    this.schoolMap = map;
    this.endLoading();
  }

  public initialize(): void {
    if(!!!this._currentSchoolId) {
      // initialize from cookie store
      var schoolId = this.cookieService.get('school_id');
      if(schoolId != null) {
        this._currentSchoolId = parseInt(schoolId);
      }
    }
  }

  public startLoading(): void {
    this._isLoading = true;
  }

  public endLoading(): void {
    this._isLoading = false;
  }

  public isLoading(): boolean {
    return this._isLoading;
  }

  public currentSchoolId():number | null{
    return this._currentSchoolId;
  }

  public pastCutoffTime(route: Route, dateTimeStr: string | null): string {
    const times = this.currentSchool()?.substitution_snapshot_times;
    var dateTime = null;
    
    if(times != null && times.length > 0) {
      for(var timeString of times) {
        var [hours, minutes] = timeString.split(":").map(Number);
        if(hours != null && minutes != null) {
          if(( hours < 12 && route.am) || (hours >= 12 && !route.am)) {
            if(dateTimeStr == null) {
              // "today"
              dateTime = new Date();
            } else {
               // else dateTime is route last_updated
              dateTime = new Date(dateTimeStr);
            }
            
            // set up the cutoff time today
            const todayAtCutoffTime = new Date();
            // adjust based on cutoff
            if(route.delay_early_offset != null) {
              hours += route.delay_early_offset 
            }

            todayAtCutoffTime.setHours(hours, minutes, 0, 0); // Set seconds and milliseconds to 0
            const time = DateTime.fromJSDate(todayAtCutoffTime)

            if(dateTime > todayAtCutoffTime) {
              return time.toFormat('h:mma').toLowerCase();
            }    
          }
        }
      }
    }
    return '';
  }

  public currentSchool():SchoolEntry|null {
    if (!!this._currentSchoolId) {
      var school:SchoolEntry|undefined = this.schoolMap.get(this._currentSchoolId);
      if(!!school) {
        return school
      }
    }
    return null;
  }

  public setCurrentSchoolId(selected:string) {
    this._currentSchoolId = parseInt(selected, 10);

    const cookieDomain = environment.cookieDomain;
    const cookieExpiry = this.cookieExpiry();
        // store current one
    if (!!this._currentSchoolId) {
      this.cookieService.set(this.cookieName(), this._currentSchoolId.toString(), {path: '/', domain: `.${cookieDomain}`, expires: cookieExpiry});
    }
  }

  private cookieName(): string {
    return 'school_id';
  }

  private cookieExpiry(): Date {
    let date = new Date();
    // Add 1 years to the current date
    date.setFullYear(date.getFullYear() + 1);
    return date;
  }
  public hasMemberSchools(): boolean|null {
    if (!!this._currentSchoolId) {
      var school:SchoolEntry|undefined = this.schoolMap.get(this._currentSchoolId);
      if(!!school) {
        return school.supports_member_school_filtering;
      }
    }
    return null;
  }
  public hasRiders(): boolean|null {
    if (!!this._currentSchoolId) {
      var school:SchoolEntry|undefined = this.schoolMap.get(this._currentSchoolId);
      if(!!school) {
        return school.supports_rider_counting || school.supports_rider_tracking;
      }
    }
    return null;
  }
  public isSchool(): boolean|null {
    if (!!this._currentSchoolId) {
      var school:SchoolEntry|undefined = this.schoolMap.get(this._currentSchoolId);
      if(!!school) {
        return school.is_school
      }
    }
    return null;
  }
  public supportsBellTimes(): boolean|null {
    if (!!this._currentSchoolId) {
      var school:SchoolEntry|undefined = this.schoolMap.get(this._currentSchoolId);
      if(!!school) {
        return school.supports_bell_times;
      }
    }
    return null;
  }
}
