import { Injectable } from '@angular/core';
import {
  HttpClient,
  HttpErrorResponse,
  HttpHeaders,
  HttpParams,
  HttpResponse,
  HttpStatusCode,
} from '@angular/common/http';

import { Observable } from 'rxjs';

import {ApiData, ApiObject, Report, SchoolEntry, Substitution, VehicleSubstitution} from '@app/interfaces/api.objects';
import { UserService } from '@app/services/user.service';
import { SchoolsService } from '@app/services/schools.service';
import { MemberSchoolsService } from '@app/services/member_schools.service';
import { environment } from '@env';

@Injectable({
  providedIn: 'root',
})
export class ApiService {
  private readonly API_URI = environment.apiUri;
  private readonly BUS_COMPANIES_PATH = '/bus_companies';
  private readonly DASHBOARD_PATH = '/dashboard';
  private readonly MEMBER_SCHOOLS_URL = '/member_schools';
  private readonly MEMBER_SCHOOL_GROUPS_URL = '/member_school_groups';
  private readonly PRIMARY_ADMINS_URL = '/primary_admins';
  private readonly REPORTS_PATH = '/reports';
  private readonly REPORT_SCHEDULES_PATH = '/report_schedules';
  private readonly SCHOOLS_PATH = '/schools';
  private readonly SUBSTITUTIONS_PATH = '/primary_assignments';
  private readonly TIMEZONES_PATH = '/timezones';
  private readonly DEVICES_PATH = '/devices';
  private readonly ROUTES_PATH = '/routes';
  private readonly VENDORS_PATH = '/vendors';

  constructor(
    private http: HttpClient,
    private userService: UserService,
    private schoolsService: SchoolsService,
    private memberSchoolsService: MemberSchoolsService,
  ) {
  }

  public parseApiData<T>(apiData: ApiData<T> | null, includeType: boolean = false): T[] {
    if (!apiData) {
      return [];
    }
    
    let data = apiData.data;
    if (!Array.isArray(data)) {
      data = [data];
    }

    return data.map((data: ApiObject<T>): T => {
      const attrs: T = data.attributes;
      if (includeType) {
        // @ts-ignore
        Object.assign(attrs, { type: data.type });
      }

      return attrs;
    });
  }

  // Dynamic navigation tabs
  // GET /api/v1/dashboard/admin_tabs
  public getAdminTabs(onSuccess: Function, onError?: Function): void {
    this.handleAuth(this.http.get(this.dashboardUrl(undefined, '/admin_tabs'), this.options()), onSuccess, onError);
  }

  // Dynamic navigation tabs
  // GET /api/v1/dashboard/admin_tabs
  public getAdminTodos(onSuccess: Function, onError?: Function): void {
    this.handleAuth(this.http.get(this.dashboardUrl(undefined, '/admin_todos'), this.options()), onSuccess, onError);
  }

  // Dynamic navigation links
  // GET /api/v1/dashboard/links
  public getLinks(onSuccess: Function, onError?: Function): void {
    this.handleAuth(this.http.get(this.dashboardUrl(undefined, '/links'), this.options()), onSuccess, onError);
  }

  // Routes table
  // GET /api/v1/dashboard/routes
  public getDashboardRoutes(onSuccess: Function, onError?: Function): void {
    this.handleAuth(this.http.get(this.dashboardUrl(undefined, '/routes'), this.options()), onSuccess, onError);
  }

  // Stats table
  // GET /api/v1/dashboard/stats
  public getStats(onSuccess: Function, onError?: Function): void {
    this.handleAuth(this.http.get(this.dashboardUrl(undefined, '/stats'), this.options()), onSuccess, onError);
  }

  // Trackers table
  // GET /api/v1/dashboard/trackers
  public getTrackers(onSuccess: Function, onError?: Function): void {
    this.handleAuth(this.http.get(this.dashboardUrl(undefined, '/trackers'), this.options()), onSuccess, onError);
  }

  // JWT validation
  // GET /api/v1/dashboard/validate
  public getValidate(onSuccess: Function, onError?: Function): void {
    this.handleAuth(this.http.get(this.dashboardUrl(undefined, '/validate'), this.options()), onSuccess, onError);
  }

  // BusCompanies
  // GET /api/v1/bus_companies
  public getBusCompanies(onSuccess: Function, onError?: Function): void {
    this.handleAuth(this.http.get(this.busCompaniesUrl(), this.options()), onSuccess, onError);
  }

  // PrimaryAdmin
  // GET /api/v1/primary_admins
  public getPrimaryAdmin(email: string, onSuccess: Function, onError?: Function): void {
    let params = new HttpParams();
    params = params.set('email', email);
    this.handleAuth(this.http.get(this.primaryAdminsUrl(), this.options(params)), onSuccess, onError);
  }

  // Schools - per-user
  // GET /api/v1/schools
  public getAllSchools(onSuccess: Function, onError?: Function): void {
    let params = new HttpParams();
    this.handleAuth(this.http.get(this.schoolsUrl(), this.options(params)), onSuccess, onError);
  }

  // POST /api/v1/schools
  public createSchool(newSchool: any, onSuccess: Function, onError?: Function): void {
    // copy data so we don't mess with original popup
    newSchool = Object.assign({}, newSchool);

    // no need to waste time sending errors
    delete newSchool.errors;

    // filter out null and undefined values so they don't get passed as text
    const filtered = Object.entries(newSchool).filter(([ _key, value ]) => {
      return !(value === null || value === undefined);
    });
    // @ts-ignore
    newSchool = Object.fromEntries(filtered);

    // @ts-ignore
    const params = new HttpParams({ fromObject: newSchool });
    this.handleAuth(this.http.post(this.schoolsUrl(), null, this.options(params)), onSuccess, onError);
  }

  // PATCH /api/v1/schools
  public updateSchool(school: SchoolEntry, hasNewPrimaryAdmin: boolean, onSuccess: Function, onError?: Function): void {
    // copy data so we don't mess with original popup
    school = Object.assign({}, school);

    // no need to waste time sending errors
    delete school.errors;

    // filter out null and undefined values so they don't get passed as text
    const filtered = Object.entries(school).filter(([ key, value ]) => {
      return (key === 'disable_account_on') || !(value === null || value === undefined);
    });
    // @ts-ignore
    school = Object.fromEntries(filtered);

    // @ts-ignore
    school['has_new_primary_admin'] = hasNewPrimaryAdmin;
    // @ts-ignore
    school['primary_admin_email'] = school.primary_admin?.email;
    delete school.primary_admin;

    if (!!school.uid) {
      // properly format data
      school.owning_bus_company_id = school.owning_bus_company?.id;
      if (Array.isArray(school.ftp_notification_emails)) {
        school.ftp_notification_emails = school.ftp_notification_emails.join(',');
      }
      if (Array.isArray(school.whitelists)) {
        school.whitelists = school.whitelists.join(',');
      }

      // @ts-ignore
      const params = new HttpParams({ fromObject: school });
      this.handleAuth(this.http.patch(this.schoolsUrl(school.uid), null, this.options(params)), onSuccess, onError);
    }
  }

  // Schools - for superusers
  // GET /api/v1/schools/all
  public getSchools(onSuccess: Function, onError?: Function): void {
    this.handleAuth(this.http.get(this.schoolsUrl('all'), this.options()), onSuccess, onError);
  }

  // GET /api/v1/schools/valid_countries
  public getValidCountries(onSuccess: Function, onError?: Function): void {
    this.handleAuth(this.http.get(this.schoolsUrl('valid_countries'), this.options()), onSuccess, onError);
  }

  // Devices
  // GET /api/v1/devices
  public getVehiclesWithPrimaryRoutes(parameters: any, onSuccess: Function, onError?: Function): void {
    let params = new HttpParams({fromObject: parameters});
    params = params.set('include', 'primary_routes');
    this.handleAuth(this.http.get(this.devicesUrl(), this.options(params)), onSuccess, onError);
  }

  // Devices/Routes
  // GET /api/v1/devices/:device_id/routes
  // TODO replace with call to regular /api/v1/routes with device_id filter parameter
  public getDeviceRoutes(deviceId: number, am: boolean, onSuccess: Function, onError?: Function): void {
    let params = new HttpParams();
    params = params.set('am', am.toString());
    this.handleAuth(this.http.get(this.devicesUrl(deviceId.toString(), '/routes'), this.options(params)), onSuccess, onError);
  }

  // MemberSchools
  // GET /api/v1/member_schools
  public getMemberSchools(memberSchoolIds: String[], onSuccess: Function, onError?: Function): void {
    let params = new HttpParams();
    params = params.set('member_school_ids', memberSchoolIds.toString());
    this.handleAuth(this.http.get(this.memberSchoolsUrl(), this.options(params)), onSuccess, onError);
  }

  // MemberSchoolGroups
  // GET /api/v1/member_school_groups
  public getMemberSchoolGroups(memberSchoolGroupIds: String[], onSuccess: Function, onError?: Function): void {
    let params = new HttpParams();
    params = params.set('member_school_group_ids', memberSchoolGroupIds.toString());
    this.handleAuth(this.http.get(this.memberSchoolGroupsUrl(), this.options(params)), onSuccess, onError);
  }

  // Reports
  public createOrUpdateReport(report: Report, onSuccess: Function, onError?: Function): void {
    report = Object.assign({}, report);
    // we don't need to pass results to the backend
    delete report.results;

    if (!!report.uid) {
      this.updateReport(report, onSuccess, onError);
    } else {
      this.createReport(report, onSuccess, onError);
    }
  }

  // POST /api/v1/reports
  public createReport(report: Report, onSuccess: Function, onError?: Function): void {
    let params = new HttpParams();
    params = params.set('payload', JSON.stringify(report));

    this.handleAuth(this.http.post(this.reportsUrl(), null, this.options(params)), onSuccess, onError);
  }

  // PATCH /api/v1/reports/:uid
  public updateReport(report: Report, onSuccess: Function, onError?: Function): void {
    let params = new HttpParams();
    params = params.set('payload', JSON.stringify(report));

    this.handleAuth(this.http.patch(this.reportsUrl(report.uid), null, this.options(params)), onSuccess, onError);
  }

  // PUT /api/v1/reports/:uid/email_report
  public emailReport(report: Report, onSuccess: Function, onError?: Function): void {
    let params = new HttpParams();

    this.handleAuth(this.http.patch(this.reportsUrl(report.uid, '/email_report'), null, this.options(params)), onSuccess, onError);
  }
  // PUT /api/v1/reports/:uid/saveReport
  public saveReport(uid: string | undefined, onSuccess: Function, onError?: Function): void {
    let params = new HttpParams();

    this.handleAuth(this.http.put(this.reportsUrl(uid, '/save_report'), null, this.options(params)), onSuccess, onError);
  }

  // PUT /api/v1/reports/:uid/unsaveReport
  public unsaveReport(uid: string | undefined, onSuccess: Function, onError?: Function): void {
    let params = new HttpParams();

    this.handleAuth(this.http.put(this.reportsUrl(uid, '/unsave_report'), null, this.options(params)), onSuccess, onError);
  }
  // GET /api/v1/reports/
  public getReports(onSuccess: Function, onError?: Function): void {
    this.handleAuth(this.http.get(this.reportsUrl(), this.options()), onSuccess, onError);
  }

  // GET /api/v1/reports/:uid
  public getReport(uid: string, onSuccess: Function, onError?: Function): void {
    let params = new HttpParams();
    params = params.set('uid', uid);

    this.handleAuth(this.http.get(this.reportsUrl(uid), this.options(params)), onSuccess, onError);
  }

  // DELETE /api/v1/reports/:uid
  public deleteReport(uid: string, onSuccess: Function, onError?: Function): void {
    let params = new HttpParams();
    params = params.set('uid', uid);
    this.handleAuth(this.http.delete(this.reportsUrl(uid), this.options(params)), onSuccess, onError);
  }

  // PUT /api/v1/reports/:uid
  public regenerateReport(uid: string, onSuccess: Function, onError?: Function): void {
    let params = new HttpParams();
    params = params.set('uid', uid);

    this.handleAuth(this.http.put(this.reportsUrl(uid, '/regenerate'), null, this.options(params)), onSuccess, onError);
  }

  // POST /api/v1/scheduled_reports
  public saveSchedule(uid: string, reportScheduleParams: any, onSuccess: Function, onError?: Function): void {
    let params = new HttpParams();
    params = params.set('uid', uid);
    params = params.set('payload', JSON.stringify(reportScheduleParams));
    this.handleAuth(this.http.post(this.reportSchedulesUrl(), null, this.options(params)), onSuccess, onError);
  }
  // PATCH /api/v1/scheduled_reports
  public updateSchedule(uid: string, reportScheduleParams: any, onSuccess: Function, onError?: Function): void {
    let params = new HttpParams();
    params = params.set('uid', uid);
    params = params.set('payload', JSON.stringify(reportScheduleParams));
    this.handleAuth(this.http.patch(this.reportSchedulesUrl(uid), null, this.options(params)), onSuccess, onError);
  }
  // DELETE /api/v1/scheduled_reports
  public deleteSchedule(uid: string, onSuccess: Function, onError?: Function): void {
    let params = new HttpParams();
    params = params.set('uid', uid);
    this.handleAuth(this.http.delete(this.reportSchedulesUrl(uid), this.options(params)), onSuccess, onError);
  }
  
  // Substitutions
  // GET /api/v1/primary_assignments
  public getSubstitutions(startDate: string, endDate: string, dateFilterType: string, isAmRoutes: boolean | null, busId: number | null, tier: string | null, onSuccess: Function, onError?: Function): void {    
    let params = new HttpParams();
    params = params.set('start_date', startDate);
    params = params.set('end_date', endDate);
    params = params.set('date_filter_type', dateFilterType == 'date' ? 'active' : 'created_updated');

    if(isAmRoutes != null) {
      params = params.set('am', isAmRoutes);
    }

    // filters - currently just substitute but someday maybe others
    var filter = ['substitute'];
    
    if(!!busId) {
      if(!!tier) {
        params = params.set('device_id', busId);
        params = params.set('tier', tier);
        filter = filter.filter(function(item) {
          return item !== 'substitute'
        });
      } else {
        params = params.set('for_device_id', busId);
      }
    }
    
    params = params.set('filter', filter.join(","));

    this.handleAuth(this.http.get(this.substitutionsUrl(), this.options(params)), onSuccess, onError);
  }

  // DELETE /api/v1/substitutions
  public deleteSubstitutions(vehicleSubstitution: VehicleSubstitution, uniqueRequester: string | null, onSuccess: Function, onError?: Function): void {
    let params = new HttpParams();
    params = params.set('unique_requester', uniqueRequester || '');

    const subIds: number[] = vehicleSubstitution?.substitutions?.map((sub: Substitution) => sub.id) || [];
    params = params.set('devices_vehicle_ids', subIds.toString());
    this.handleAuth(this.http.delete(this.substitutionsUrl(), this.options(params)), onSuccess, onError);
  }

  // PATCH /api/v1/substitutions
  public updateSubstitution(deviceId: number, startsAt: string, expiresAt: string, subBuses: any, subBusePlaceholders: any, createPlaceholders: boolean, uniqueRequester: string | null, force: boolean, onSuccess: Function, onError?: Function): void {
    let params = new HttpParams();
    params = params.set('device_id', deviceId.toString());

    params = params.set('starts_at', startsAt || '');
    params = params.set('expires_at', expiresAt || '');

    params = params.set('unique_requester', uniqueRequester || '');
    params = params.set('force', force.toString());

    for (let i = 0; i < subBuses.length; i++) {
      const subBus = subBuses[i];
      if (!!subBus.route_id) {
        const prefix = 'assigned_' + i + '_';

        params = params.set(prefix + 'device_id', (subBus.device_id || 'null').toString());
        if (subBus.id != null) {
          // this is an existing substitution, we're editing
          params = params.set(prefix + 'devices_vehicle_id', subBus.id.toString());
        }

        params = params.set(prefix + 'route_id', subBus.route_id.toString());

        const delayMin: string = (subBus.delay_min == null ? 'null' : subBus.delay_min).toString();
        params = params.set(prefix + 'delay_min', delayMin);

        const combineStrategy: string = (subBus.combine_strategy || 'null').toString();
        params = params.set(prefix + 'combine_strategy', combineStrategy);

        const driverName: string = (subBus.driver_name || '').toString();
        params = params.set(prefix + 'driver_name', driverName);

        const attendantName: string = (subBus.attendant_name || '').toString();
        params = params.set(prefix + 'attendant_name', attendantName);
      }
    }

    if (createPlaceholders) {
      for (let i = 0; i < subBusePlaceholders.length; i++) {
        const subBus = subBusePlaceholders[i];
        if (!!subBus.route_id) {
          const prefix = 'assigned_' + (subBuses.length + i) + '_';

          params = params.set(prefix + 'device_id', (subBus.device_id || 'null').toString());
          params = params.set(prefix + 'route_id', subBus.route_id.toString());

          const delayMin: string = (subBus.delay_min == null ? 'null' : subBus.delay_min).toString();
          params = params.set(prefix + 'delay_min', delayMin);

          const driverName: string = (subBus.driver_name || '').toString();
          params = params.set(prefix + 'driver_name', driverName);

          const attendantName: string = (subBus.attendant_name || '').toString();
          params = params.set(prefix + 'attendant_name', attendantName);
        }
      }
    }

    this.handleAuth(this.http.patch(this.substitutionsUrl(), null, this.options(params)), onSuccess, onError);
  }

  // PATCH /api/v1/substitutions/lock
  public lockSubstitution(vehicleSubstitution: VehicleSubstitution, uniqueRequester: string | null, onSuccess: Function, onError?: Function): void {
    let params = new HttpParams();
    params = params.set('unique_requester', uniqueRequester || '');

    const subIds: number[] = vehicleSubstitution?.substitutions?.map((sub: Substitution) => sub.id) || [];
    params = params.set('devices_vehicle_ids', subIds.join(','));

    this.handleAuth(this.http.patch(this.substitutionsUrl('lock'), null, this.options(params)), onSuccess, onError);
  }

  // PATCH /api/v1/substitutions/unlock
  public unlockSubstitution(vehicleSubstitution: VehicleSubstitution, uniqueRequester: string | null, onSuccess: Function, onError?: Function): void {
    let params = new HttpParams();
    params = params.set('unique_requester', uniqueRequester || '');

    const subIds: number[] = vehicleSubstitution?.substitutions?.map((sub: Substitution) => sub.id) || [];
    params = params.set('devices_vehicle_ids', subIds.join(','));

    this.handleAuth(this.http.patch(this.substitutionsUrl('unlock'), null, this.options(params)), onSuccess, onError);
  }

  // GET /api/v1/substitutions/
  // TODO: replace with call to /api/v1/schools/[school_id]
  public getRealtimeSubInfo(onSuccess: Function, onError?: Function): void {
    this.handleAuth(this.http.get(this.substitutionsUrl('realtime'), this.options()), onSuccess, onError);
  }

  // Timezones
  // GET /api/v1/timezones
  public getTimezones(onSuccess: Function, onError?: Function): void {
    this.handleAuth(this.http.get(this.timezonesUrl(), this.options()), onSuccess, onError);
  }

  // Vendors
  // GET /api/v1/vendors
  public getVendors(onSuccess: Function, onError?: Function): void {
    this.handleAuth(this.http.get(this.vendorsUrl(), this.options()), onSuccess, onError);
  }

  public getRoutes(parameters: any, onSuccess: Function, onError?: Function): void {
    let params = new HttpParams({fromObject: parameters});
    this.handleAuth(this.http.get(this.routesUrl(), this.options(params)), onSuccess, onError);
  }

  public getDevices(parameters: any, onSuccess: Function, onError?: Function): void {
    let params = new HttpParams({fromObject: parameters});
    this.handleAuth(this.http.get(this.devicesUrl(), this.options(params)), onSuccess, onError);
  }
  
  private handleAuth(response: Observable<Object>, onSuccess: Function, onError?: Function): void {
    response.subscribe(
      (res: HttpResponse<Object>) => {
        // update our stored auth token
        const token = res.headers.get('Authorization')?.split(' ')?.[1];
        this.userService.storeToken(token);
        onSuccess(res);
      },
      (err: HttpErrorResponse) => {
        // token is invalid
        if (err.status === HttpStatusCode.Unauthorized) {
          console.error('Error during API request, refreshing...');
          // this.userService.reload();
        }
        if (!!onError) {
          onError(err);
        }
      });
  }

  private options(params?: HttpParams, options?: {}): any {
    const token = this.userService.getToken();
    const headers = new HttpHeaders().set('Authorization', 'Bearer ' + token);
    let paramsKeys: string[] = [];

    if (params) {
      paramsKeys = [...params.keys()];
    }
    
    const schoolId = this.schoolsService.currentSchoolId();
    if (!!schoolId) {
      if (!params) {
        params = new HttpParams();
      }
      params = params.set('school_id', schoolId);
    }
    
    if (!paramsKeys.includes('member_school_ids')){
      const memberSchoolId = this.memberSchoolsService.currentMemberSchoolId();
      if (!!memberSchoolId) {
        if (!params) {
          params = new HttpParams();
        }
        params = params.set('member_school_id', memberSchoolId);
      }
    }

    if (!paramsKeys.includes('member_school_group_ids')){
      const memberSchoolGroupId = this.memberSchoolsService.currentMemberSchoolGroupId();
      if (!!memberSchoolGroupId) {
        if (!params) {
          params = new HttpParams();
        }
        params = params.set('member_school_group_id', memberSchoolGroupId);
      }
    }
    
    return {
      headers: headers,
      params: params,
      observe: 'response',
      ...options,
    };
  }

  // URL helpers
  private dashboardUrl(id?: number, path?: string): string {
    return this.resourceUrl(this.DASHBOARD_PATH, id, path);
  }

  private busCompaniesUrl(id?: number, path?: string): string {
    return this.resourceUrl(this.BUS_COMPANIES_PATH, id, path);
  }

  private memberSchoolsUrl(uid?: string, path?: string): string {
    return this.resourceUrl(this.MEMBER_SCHOOLS_URL, uid, path);
  }

  private memberSchoolGroupsUrl(uid?: string, path?: string): string {
    return this.resourceUrl(this.MEMBER_SCHOOL_GROUPS_URL, uid, path);
  }

  private primaryAdminsUrl(uid?: string, path?: string): string {
    return this.resourceUrl(this.PRIMARY_ADMINS_URL, uid, path);
  }

  public reportsUrl(uid?: string, path?: string): string {
    return this.resourceUrl(this.REPORTS_PATH, uid, path);
  }

  public reportSchedulesUrl(uid?: string, path?: string): string {
    return this.resourceUrl(this.REPORT_SCHEDULES_PATH, uid, path);
  }

  private schoolsUrl(uid?: string, path?: string): string {
    return this.resourceUrl(this.SCHOOLS_PATH, uid, path);
  }

  private substitutionsUrl(uid?: string, path?: string): string {
    return this.resourceUrl(this.SUBSTITUTIONS_PATH, uid, path);
  }

  private devicesUrl(uid?: string, path?: string): string {
    return this.resourceUrl(this.DEVICES_PATH, uid, path);
  }

  private routesUrl(uid?: string, path?: string): string {
    return this.resourceUrl(this.ROUTES_PATH, uid, path);
  }

  private timezonesUrl(id?: number, path?: string): string {
    return this.resourceUrl(this.TIMEZONES_PATH, id, path);
  }

  private vendorsUrl(id?: number, path?: string): string {
    return this.resourceUrl(this.VENDORS_PATH, id, path);
  }

  private resourceUrl(resource: string, id?: number | string, path?: string): string {
    let url = this.API_URI + resource;

    if (id) {
      url += '/' + id;
    }

    if (path) {
      url += path;
    }

    return url;
  }

}
