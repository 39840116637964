import { Injectable } from '@angular/core';
import {CookieService} from "ngx-cookie-service";
import { environment } from '@env';
import {MemberSchool, MemberSchoolGroup} from "@app/interfaces/api.objects";

@Injectable({
  providedIn: 'root',
})
export class MemberSchoolsService {
  _currentMemberSchoolId: number | null = null;
  _currentMemberSchoolGroupId: number | null = null;
  _isLoading: boolean = false;
  memberSchoolMap: Map<number, MemberSchool> = new Map();
  memberSchoolGroupMap: Map<number, MemberSchoolGroup> = new Map();

  constructor(
    protected cookieService: CookieService,
  ) {
  }

  public startLoading(): void {
    this._isLoading = true;
  }

  public endLoading(): void {
    this._isLoading = false;
  }

  public isLoading(): boolean {
    return this._isLoading;
  }

  initialize(): void {
    // initialize from cookie store
    var memberSchoolId = parseInt(this.cookieService.get('member_school_id'), 10);

    if(!!memberSchoolId) {
      this._currentMemberSchoolId = memberSchoolId;
      this._currentMemberSchoolGroupId = null;
    } else {
      var memberSchoolGroupId = parseInt(this.cookieService.get('member_school_group_id'), 10);
      if(!!memberSchoolGroupId) {
        this._currentMemberSchoolId = null;
        this._currentMemberSchoolGroupId = memberSchoolGroupId;
      }
    }
  }

  public setMemberSchoolMap(map: Map<number, MemberSchool>) {
    this.memberSchoolMap = map;
  }

  public setMemberSchoolGroupMap(map: Map<number, MemberSchoolGroup>) {
    this.memberSchoolGroupMap = map;
  }

  public currentMemberSchoolGroupId():number|null {
    return this._currentMemberSchoolGroupId;
  }

  public currentMemberSchoolId():number|null {
    return this._currentMemberSchoolId;
  }

  public currentItem():MemberSchool|MemberSchoolGroup|null {
    var ret:MemberSchool|MemberSchoolGroup|null = null;
    if(!!this._currentMemberSchoolId) {
      ret = this.memberSchoolMap.get(this._currentMemberSchoolId) || null;
    }
    if(ret == null && !!this._currentMemberSchoolGroupId) {
      ret = this.memberSchoolGroupMap.get(this._currentMemberSchoolGroupId) || null;
    }
    return ret;
  }

  public currentItemAsString():string {
    if(!!this._currentMemberSchoolId) {
      return this.itemAsString('member_school', this._currentMemberSchoolId)
    } else if(!!this._currentMemberSchoolGroupId) {
      return this.itemAsString('member_school_group', this._currentMemberSchoolGroupId)
    } else {
      return '';
    }
  }

  public itemAsString(type:string, id:number):string {
    return id.toString() + '__' + type;
  }

  public reset() {
    this._currentMemberSchoolGroupId = null;
    this._currentMemberSchoolId = null;
    this.cookieService.delete('member_school_id', '/', `.${environment.cookieDomain}`);
    this.cookieService.delete('member_school_group_id', '/', `.${environment.cookieDomain}`);
  }

  public setFromStringValue(stringValue:string) {
    if (stringValue === 'all') {
      this._currentMemberSchoolGroupId = null;
      this._currentMemberSchoolId = null;
    } else {
      const idType = stringValue.split('__');
      const id: number = parseInt(idType[0] || '');
      const type: string = idType[1] || '';

       // clear old cookies
       this.reset();
    
      if(type == 'member_school') {
        this._currentMemberSchoolId = id;
        this.cookieService.set('member_school_id', this._currentMemberSchoolId.toString(), {path: '/', domain: `.${environment.cookieDomain}`, expires: this.cookieExpiry() });
      } else if(type == 'member_school_group') {
        this._currentMemberSchoolGroupId = id;        
        this.cookieService.set('member_school_group_id', this._currentMemberSchoolGroupId.toString(), {path: '/', domain: `.${environment.cookieDomain}`, expires: this.cookieExpiry() });
      }
    }
  }

  private cookieExpiry(): Date {
    let date = new Date();
    // Add 1 years to the current date
    date.setFullYear(date.getFullYear() + 1);
    return date;
  }
}
