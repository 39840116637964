import { ModuleWithProviders, NgModule } from '@angular/core';
import { CommonModule, NgOptimizedImage } from '@angular/common';
import { RouterOutlet } from '@angular/router';
import {
  NbIconModule,
  NbMenuModule,
  NbSidebarModule,
  NbThemeModule,
} from '@nebular/theme';
import { NbEvaIconsModule } from '@nebular/eva-icons';

import { HeaderComponent } from './components';

import { DEFAULT_THEME } from './styles/theme.default';
import {BsDropdownModule} from "ngx-bootstrap/dropdown";

const NB_MODULES = [
  NbEvaIconsModule,
  NbIconModule,
  NbMenuModule,
  NbSidebarModule,
];
const COMPONENTS = [
  HeaderComponent,
];

@NgModule({
    imports: [CommonModule, ...NB_MODULES, NgOptimizedImage, RouterOutlet, BsDropdownModule],
  exports: [ CommonModule, ...COMPONENTS ],
  declarations: [ ...COMPONENTS ],
})
export class ThemeModule {
  static forRoot(): ModuleWithProviders<ThemeModule> {
    return {
      ngModule: ThemeModule,
      providers: NbThemeModule.forRoot({ name: 'default' }, [ DEFAULT_THEME ]).providers || [],
    };
  }
}
